import { useCallback, useEffect } from 'react'
// import { Link, useNavigate } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

// components
import { LoadingMaster } from '@components/LoadingMaster'

// css
import './PopupAuth.scoped.scss';

export const PopupAuth = ({ children, title, style, latestUrl }) => {

    // const navigate = useNavigate();
    const history = useHistory();

    const handleClose = useCallback(() => {
        // navigate(`/`);
        history.push(latestUrl || `/`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        function searchKeyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }
        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)
    }, [handleClose]);

    return (
        <div className="popup-fs">
            <Link className="btn btn--close" style={{ backgroundColor: style.backgroundColor }} to={'/'}>.</Link>
            <div className="col col--bg">
                <div className="box">
                    <div className="logo"></div>
                    <h3>{title}</h3>
                    <p>By joining Confessum you are agreeing to our <Link to={"/privacy-policy"}>Privacy Policy</Link>, <Link to={"/cookie-policy"}>Cookie Policy</Link> and  <Link to={"/terms-of-use"}>Terms of Use</Link>. </p>
                </div>
                <div className="bg">
                    <LoadingMaster style={{ position: 'static', backgroundColor: style.backgroundColor }} />
                </div>
            </div>
            <div className="col">
                <div className="box">
                    {children}
                </div>
            </div>
        </div>
    )

}