import uuidv4 from '@utils/uuidv4';

// css
import './Search.scoped.scss';

const randomValue = uuidv4();

export const Search = () => {

    return (
        <div className="search">
            <form>
                <input
                    autoComplete={`search-${randomValue}`}
                    name={`search-${randomValue}`}
                />
            </form>
        </div>
    );

}
