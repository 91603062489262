import { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// hooks
import { useAlertContext } from '@hooks/useAlertContext'

// images
import icoOk from '@assets/img/alert/ok.svg';
import icoError from '@assets/img/alert/error.svg';
import icoMail from '@assets/img/alert/mail.svg';

// css
import './AlertReact.scoped.scss';

const Ico = styled.div`
width: 80px;
height: 80px;
margin: 0 auto 20px;
content: '';
display: block;
background-color: white;
mask-repeat: no-repeat;
mask-position: center;
mask-image: ${(props) => `url(${props.image})`};
`

export const AlertReact = (() => {

    const focusButton = useRef(null);

    const { alertReact, alertDispatch } = useAlertContext();

    const handleClose = useCallback(() => {
        alertDispatch({ type: "CLOSE" });
    }, [alertDispatch]);

    useEffect(() => {
        function keyPressHandler(e) { if (e.keyCode === 27) { handleClose(); } }
        setTimeout(() => { if (focusButton && focusButton.current) focusButton.current.focus(); }, 50);
        document.addEventListener("keyup", keyPressHandler);
        return () => { document.removeEventListener("keyup", keyPressHandler); }
    }, [handleClose]);

    return (

        <div className="box">
            <Ico image={alertReact.ico === 'error' ? icoError : alertReact.ico === 'mail' ? icoMail : icoOk}></Ico>
            <div className="area">{alertReact.text}</div>
            {
                alertReact.callback && (
                    <div className="button-area button-area--confirm">
                        <button onClick={() => {
                            alertReact.callback();
                            handleClose();
                        }} className="btn btn--ok button">Ok</button>
                        <button ref={focusButton} onClick={handleClose} className="btn button">Cancel</button>
                    </div>
                )
            }
            {
                !alertReact.callback && (
                    <div className="button-area button-area--ok">
                        <button ref={focusButton} onClick={handleClose} className="btn button">Ok</button>
                    </div>
                )
            }
        </div>

    );

})