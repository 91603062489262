import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import config from '@/config'

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (
            // true ||
            !window?.location?.href?.includes("localhost") &&
            !window?.location?.href?.includes("noanalytics")
        ) {
            // ReactGA.initialize(config.GA);
            ReactGA.initialize([
                { trackingId: config.GA }
            ]);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            // ReactGA.pageview(location.pathname + location.search);
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
            // console.log(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default usePageTracking;