import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

// components
import {
    PopupTransition
} from '@components/Transitions'

// views
import { Register } from '@views/Register'
import { Login } from '@views/Login'

export const PopupLoginRegister = ({ pathname }) => {

    const [showLoginRegister, setShowLoginRegister] = useState(false)
    const [latestUrl, setLatestUrl] = useState('/')

    useEffect(() => {
        if (pathname === '/login') setShowLoginRegister('login')
        else if (pathname === '/register') setShowLoginRegister('register')
        else {
            setShowLoginRegister(false)
            setLatestUrl(pathname)
        }
    }, [pathname])

    return (
        <>
            <AnimatePresence>
                {(showLoginRegister === 'login') && <PopupTransition style={{ zIndex: 9000, position: 'fixed' }}><Login latestUrl={latestUrl} /></PopupTransition>}
                {(showLoginRegister === 'register') && <PopupTransition style={{ zIndex: 9000, position: 'fixed' }}><Register latestUrl={latestUrl} /></PopupTransition>}
            </AnimatePresence>
        </>
    );
}
