export default function defaultErrors(e) {

    let response = false;
    response = e?.response?.data?.error_description;
    if (!response) response = e?.response?.data?.message;
    if (!response) response = e?.response?.data?.error?.message;
    if (!response) response = e?.err?.response?.data?.message;
    if (!response) response = e?.err?.response?.data?.error?.message;
    if (!response) response = e?.err?.message;
    if (!response) response = e?.message;
    if (!response) response = JSON.stringify(e);
    if ((typeof response) !== 'string') response = JSON.stringify(e);
    return response;

}