import { Link } from "react-router-dom";
import _ from "lodash";

import { useEffect, useState } from 'react'

// css
import './Logo.scoped.scss';

function emoFoo() {

    let emo = [
        ['😀', '😁', '😂', '😃'],
        ['😄', '😅', '😆', '😇'],
        ['😉', '😊', '😋', '😍'],
        ['😌', '😎', '😏', '😝'],
        ['😗', '😘', '😙', '😚'],
        ['😱', '😛', '😲', '😜'],
        ['😸', '😹', '😺', '😻'],
        ['😼', '😾', '🙀', '😽'],
        ['🙉', '🙈', '🙊', '🙉'],
        ['🤘', '🤙', '🤛', '🤚'],
        ['🤜', '🤟', '🤞', '🤝'],
    ]
    emo = _.shuffle(emo)
    emo = emo[0]
    emo = _.shuffle(emo)
    emo = [...emo, ...emo, ...emo, ...emo, ...emo, ...emo];

    return emo;

}

export const Logo = ({ handleClose }) => {

    const [counter, setCounter] = useState(0);
    const [emo, setEmo] = useState(emoFoo());

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(el => el + 1)
        }, 300);
        if (counter >= 16 - 1) clearInterval(interval)
        return () => clearInterval(interval);
    }, [counter]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(0)
            setEmo(emoFoo())
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Link to="/" onClick={handleClose}>
            <div className="logo">
                <span>{emo[counter]}</span>
                confessum
            </div>
        </Link>
    );

}
