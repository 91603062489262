import { useEffect } from 'react'

// css
import './LoadingMaster.scoped.scss';

export const LoadingMaster = ({ style }) => {

    useEffect(() => {

        const el = document.querySelector('html[class*="l-"]');
        for (let i = el?.classList?.length - 1; i >= 0; i--) {
            const className = el?.classList[i];
            if (className.startsWith('l-')) {
                el.classList.remove(className);
            }
        }

        document.querySelector('html').classList.add('l-' + (Math.floor(Math.random() * 10)));

        function loading() {
            setTimeout(function () {
                window.scrollTo(0, 0);
                document.querySelector('html').classList.add('loaded');
            }, 500);
        }
        window.addEventListener('load', loading);

    }, []);

    return (
        <div className={`loading-animation`} style={style || null}>
            <div className="animation">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );

}
