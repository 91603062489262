import CryptoJS from 'crypto-js'

function emojiFromStringToArray(emoji) {
    let result = [];
    for (let i = 0; i < emoji.length; i = i + 2) {
        const value = (emoji[i] + emoji[i + 1]);
        result.push(value)
    }
    return result;
}

const emoji = '🗿😀😁😂😃😄😅😆😇😈😉😊😋😌😍😎😏😐😑😒😓😔😕😖😗😘😙😚😛😜😝😞😟😠😡😢😣😤😥😦😧😨😩😪😫😬😭😮😯😰😱😲😳😴😵😶😷😸😹😺😻😼😽😾😿🙀🙁🙂🙃🙄🙅🙆🙇🙈🙉🙊🙋🙌🙍🙎🙏🚀🚁🚂🚃🚄🚅🚆🚇🚈🚉🚊🚋🚌🚍🚎🚏🚐🚑🚒🚓🚔🚕🚖🚗🚘🚙🚚🚛🚜🚝🚞🚟🚠🚡🚢🚣🚤🚥🚦🚧🚨🚩🚪🚫🚬🚭🚮🚯🚰🚱🚲🚳🚴🚵🚶🚷🚸🚹🚺🚻🚼🚽🚾🚿🛀🛁🛂🛃🛄🛅🛋🛌🛍🛎🛏🛐🛑🛒🛠🛡🛢🛣🛤🛥🛩🛫🛬🛰🛳🛴🛵🛶🛷🛸🛹🛺🤐🤑🤒🤓🤔🤕🤖🤗🤘🤙🤚🤛🤜🤝🤞🤟🤠🤡🤢🤣🤤🤥🤦🤧🤨🤩🤪🤫🤬🤭🤮🤯🤰🤱🤲🤳🤴🤵🤶🤷🤸🤹🤺🤼🤽🤾🥀🥁🥂🥃🥄🥅🥇🥈🥉🥊🥋🥌🥍🥎🥏🥐🥑🥒🥓🥔🥕🥖🥗🥘🥙🥚🥛🥜🥝🥞🥟🥠🥡🥢🥣🥤🥥🥦🥧🥨🥩🥪🥫🦀🦁🦂🦃🦄🦅🦆🦇🦈🦉🦊🦋🦌🦍🦎🦏🦐🦑🦒🦓🦔🦕🦖🦗🧀🧐🧑🧒🧓🧔🧕🧖🧗🧘🧙🧚🧛🧜🧝🧞🧟🧠🧡🧢🧣🧤🧥🧦';
const emojiArray = emojiFromStringToArray(emoji)

function convertEmojiStringToArray({ text }) {
    let result = [];
    for (let i = 0; i < text.length; i = i + 2) {
        result.push(text[i] + text[i + 1]);
    }
    return result;
}

function checkEmoji({ text }) {
    let result = true;
    for (let i = 0; i < text.length; i = i + 2) {
        const value = (text[i] + text[i + 1]);
        const index = emojiArray.find(el => el === value)
        if (!index) {
            result = false;
            break;
        }
    }
    return result;
}

function addEmoji({ text }) {
    let result = '';
    for (let i = 0; i < text.length; i++) { result += emojiArray[text.codePointAt(i)] }
    return result;
}

function removeEmoji({ text }) {
    let result = '';
    for (let i = 0; i < text.length; i = i + 2) {
        const value = (text[i] + text[i + 1]);
        const index = emojiArray.findIndex(el => el === value)
        result += String.fromCodePoint(index)
    }
    return result;
}

function encrypt({ text, key }) {
    let result = '';
    try { result = CryptoJS.AES.encrypt(text, key, { mode: CryptoJS.mode.ECB }).toString() } catch (e) { }
    return result;
}

function decrypt({ text, key }) {
    let result = '';
    try { result = CryptoJS.AES.decrypt(text, key, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8) } catch (e) { }
    return result;
}

function removeCf({ text }) {
    return text;
    // if (!text) return '';
    // if (text.startsWith('<cf>') && text.endsWith('</cf>')) {
    //     text = text.substr(4);
    //     text = text.slice(0, -5);
    // }
    // return text
}

function checkCf({ text }) {
    return text;
    // if (!text) return '';
    // return text.startsWith('<cf>') && text.endsWith('</cf>') ? text : '';
}

function addCf({ text }) {
    return text;
    // return `<cf>${text}</cf>`
}

export {
    emoji,
    convertEmojiStringToArray,
    addEmoji,
    checkEmoji,
    removeEmoji,
    encrypt,
    decrypt,
    removeCf,
    checkCf,
    addCf
}