import { useState, useEffect, lazy } from 'react'
import { Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// hooks
import { useStorageContext } from '@hooks/useStorageContext'

// components
import {
    // PageTransition,
    SimpleOpacity,
    // PopupTransition
} from '@components/Transitions'

// utils
import { isPostUrl } from '@utils/urls'

// views
// import { Home } from '@views/Home'
// import { PrivacyPolicy } from '@views/PrivacyPolicy'
// import { CookiePolicy } from '@views/CookiePolicy'
// import { TermsOfUse } from '@views/TermsOfUse'
// import { User } from '@views/User'
// import { Settings } from '@views/Settings'

function lazyFoo(page) {
    return lazy(() => {
        return new Promise(resolve => {
            setTimeout(() => resolve(
                import(`@views/${page}`).then((module) => ({ default: module[page] }))
            ), 500);
        });
    });
}

const Home = lazyFoo(`Home`);
const PrivacyPolicy = lazyFoo(`PrivacyPolicy`);
const CookiePolicy = lazyFoo(`CookiePolicy`);
const TermsOfUse = lazyFoo(`TermsOfUse`);
const User = lazyFoo(`User`);
const Settings = lazyFoo(`Settings`);

function AnimatedRoutes({
    pathname,
    data,
    setData,
    setIsLoading
}) {

    const { storage } = useStorageContext();
    const token = storage?.token;

    const [lastestUrl, setLatestUrl] = useState(false)

    useEffect(() => {
        if (!isPostUrl({ pathname })) {
            const value = pathname?.startsWith('/@') ? 'user' : 'home';
            setLatestUrl(value)
        }
    }, [pathname])

    return (
        <AnimatePresence>
            <Switch>

                {/* {!token && <Route path="/login"><PopupTransition style={{ zIndex: 9000, position: 'fixed' }}><Login /></PopupTransition></Route>}
                {!token && <Route path="/register"><PopupTransition style={{ zIndex: 9000, position: 'fixed' }}><Register /></PopupTransition></Route>} */}

                <Route path="/privacy-policy"><SimpleOpacity><PrivacyPolicy /></SimpleOpacity></Route>
                <Route path="/cookie-policy"><SimpleOpacity><CookiePolicy /></SimpleOpacity></Route>
                <Route path="/terms-of-use"><SimpleOpacity><TermsOfUse /></SimpleOpacity></Route>

                {token && <Route path="/settings"><SimpleOpacity><Settings /></SimpleOpacity></Route>}

                <Route path="/@:id"><SimpleOpacity><User data={data} setData={setData} /></SimpleOpacity></Route>
                <Route path="/">
                    <SimpleOpacity>
                        {lastestUrl === 'user' ?
                            <User data={data} setData={setData} />
                            :
                            <Home
                                data={data}
                                setIsLoading={setIsLoading}
                                setData={setData}
                                pathname={pathname}
                            />
                        }
                    </SimpleOpacity>
                </Route>

            </Switch>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;