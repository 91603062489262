import { useEffect, useState, Suspense } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from "react-router-dom";

// hooks
import usePageTracking from '@hooks/usePageTracking'
import { useStorageContext } from '@hooks/useStorageContext'
import { useAlertContext } from '@hooks/useAlertContext'
import { useStyleMediaQuery } from '@hooks/useStyleMediaQuery'

// components
import {
  AlertTransition,
  SimpleOpacity,
  PopupTransition
} from '@components/Transitions'
import { AlertReact } from '@components/AlertReact'
import { LoadingMaster } from '@components/LoadingMaster'
import { Cookies, CookiesAnimation } from '@components/Cookies'
import { Header } from '@components/Header'
import { HeaderEmpty } from '@components/HeaderEmpty'
import { Crypter } from '@components/Crypter'
import { Popup } from '@components/Popup'
import { PopupPost } from '@components/Post'
import { PopupLoginRegister } from '@components/PopupAuth'
import { DebugLogin } from '@components/DebugLogin'
// import { Logo } from '@components/Logo'

// routes
import AnimatedRoutes from './AnimatedRoutesV5'

function App() {

  usePageTracking();

  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 767 });

  const { alertReact } = useAlertContext();
  const { storage } = useStorageContext();
  const token = storage?.token;
  const cookies = storage?.cookies;

  const location = useLocation();
  const pathname = location.pathname;

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [showPost, setShowPost] = useState(false)
  const [showCrypter, setShowCrypter] = useState(false)

  const [loadingMaster, setLoadingMaster] = useState(true)
  useEffect(() => { setTimeout(() => { setLoadingMaster(false) }, 1000) }, [])

  const [showDebugLoginIfLive, setShowDebugLoginIfLive] = useState(window.location.hostname !== 'localhost')

  return (
    <>
      {showDebugLoginIfLive && <DebugLogin init={setShowDebugLoginIfLive} />}
      {!showDebugLoginIfLive && <>
        <AnimatePresence>{!cookies && <CookiesAnimation><Cookies /></CookiesAnimation>}</AnimatePresence>

        {!token && <PopupLoginRegister pathname={pathname} />}

        <PopupPost
          pathname={pathname}
          showPost={showPost}
          setShowPost={setShowPost}
          setData={setData}
          setIsLoading={setIsLoading}
        />
        <AnimatePresence>{showCrypter && <PopupTransition style={{ zIndex: 900, position: 'fixed' }}><Popup handleClose={() => { setShowCrypter(false) }}><Crypter setData={setData} /></Popup></PopupTransition>}</AnimatePresence>
        <AnimatePresence>{(loadingMaster) && <SimpleOpacity style={{ zIndex: 9000, position: 'fixed' }}><LoadingMaster /></SimpleOpacity>}</AnimatePresence>
        <AnimatePresence>{alertReact.show && <AlertTransition><AlertReact state={alertReact} /></AlertTransition>}</AnimatePresence>

        <>
          <Header
            isMobile={isMobile}
            isLoading={isLoading}
            setShowPost={setShowPost}
            setShowCrypter={setShowCrypter}
          />
          {!isMobile && <HeaderEmpty />}
        </>

        <Suspense fallback={<LoadingMaster style={{ zIndex: 900, position: 'absolute' }} />}>
          <AnimatedRoutes
            pathname={pathname}
            data={data}
            setData={setData}
            setIsLoading={setIsLoading}
          />
        </Suspense>
      </>
      }
    </>
  );
}

export default App;
