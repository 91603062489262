import { motion } from 'framer-motion'

export const CookiesAnimation = ({ children }) => {

    return (

        <motion.div
            initial={{ opacity: 0, translateY: '100%', translateX: '-50%' }}
            animate={{ opacity: 1, translateY: 0, translateX: '-50%' }}
            exit={{ opacity: 0, translateY: '100%', translateX: '-50%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            style={{
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.05)',
                bottom: '10px',
                left: '50%',
                position: 'fixed',
                zIndex: 100,
                maxWidth: '460px',
                width: 'calc(100% - 20px)',
                backgroundColor: 'rgba(51, 51, 51, 0.9)',
                color: 'white',
                fontSize: '15px'
            }}
        >{children}</motion.div>

    );

}