import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
// import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";

// utils
import { isPostUrl } from '@utils/urls'

// components
import {
    PopupTransition
} from '@components/Transitions'
import { Popup } from '@components/Popup'
import { Post } from '@components/Post'

export const PopupPost = ({ pathname, setIsLoading, showPost, setShowPost }) => {

    // const navigate = useNavigate();
    const history = useHistory();

    const [latestUrl, setLatestUrl] = useState('/')
    useEffect(() => {
        function fetchPost({ id }) {
            setIsLoading(true);
            setTimeout(() => {
                setShowPost({
                    id,
                    isPopup: true
                })
                setIsLoading(false)
            }, 1000);
        }
        const id = isPostUrl({ pathname });
        if (id) fetchPost({ id });
        else setLatestUrl(pathname)
    }, [pathname, setShowPost, setIsLoading])

    return (
        <AnimatePresence>{showPost && <PopupTransition style={{ zIndex: 900, position: 'fixed' }}><Popup handleClose={() => {
            // window.history.pushState("", "", latestUrl);
            // navigate(latestUrl, { replace: true })
            history.push(latestUrl)
            setShowPost(false)
        }}><Post data={{ id: showPost }} /></Popup></PopupTransition>}</AnimatePresence>
    );
}
