import { AnimatePresence } from 'framer-motion'

// css
import './Header.scoped.scss';

import { Logo } from '@components/Logo/Logo'
import { Settings } from './Settings'
import { Search } from './Search'
import { LoadingBar, LoadingBarAnimation } from '@components/LoadingBar'

export const Header = ({ isLoading, setShowCrypter, setShowPost, isMobile }) => {

    return (
        <>
            <div className="header" style={isMobile ? { position: 'relative', justifyContent: 'center', height: 70 } : null}>
                <Logo handleClose={() => { setShowCrypter(false); setShowPost(false); }} />
                {!isMobile && <div className="nav">
                    <Search />
                    <Settings setShowCrypter={setShowCrypter} />
                </div>}
            </div>
            <AnimatePresence>{isLoading && <LoadingBarAnimation><LoadingBar /></LoadingBarAnimation>}</AnimatePresence>
        </>
    );

}
