import { Link } from "react-router-dom";

// hooks
import { useStorageContext } from '@hooks/useStorageContext'

// css
import './Cookies.scoped.scss';

export const Cookies = () => {

    const { dispatch } = useStorageContext();

    function setCookie({ payload }) { dispatch({ type: 'ADD_STORAGE', label: 'cookies', payload }); }

    return (
        <>
            <h2>Do you like cookies?</h2>
            <p>Confessum uses cookies to provide you with a better, safer and faster service. Some cookies are necessary to use our services, improve our services, and make sure they work properly. <Link to={'cookie-policy'}>Show more about your choices</Link>.</p>
            <div className="buttons">
                <button onClick={(e) => setCookie({ payload: 'all' })}>Accept all cookies</button>
                <button onClick={(e) => setCookie({ payload: 'essential' })}>Refuse non-essential cookies</button>
            </div>
        </>
    );

}
