import React from 'react';
// import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { AlertContextProvider } from './context/AlertContext';
import { StorageContextProvider } from './context/StorageContext';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StorageContextProvider>
    <AlertContextProvider>
      <Router>
        <App />
      </Router>
    </AlertContextProvider>
  </StorageContextProvider>
);

// ReactDOM.render(
//   // <React.StrictMode>
//   <StorageContextProvider>
//     <AlertContextProvider>
//       <App />
//     </AlertContextProvider>
//   </StorageContextProvider>
//   // </React.StrictMode>
//   ,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();