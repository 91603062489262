import { Link, useHistory } from "react-router-dom";
import { useState } from 'react'

// hooks
import { useAlertContext } from '@hooks/useAlertContext'

// components
import { PopupAuth } from '@components/PopupAuth'

// utils
import defaultErrors from '@utils/defaultErrors'
import validateEmail from '@utils/validateEmail'

// services
import { register, cancelToken } from '@services/authService'

// css
import './Register.scoped.scss';

export const Register = (({ latestUrl }) => {

    const history = useHistory();

    const { alertDispatch } = useAlertContext();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    function handleUsername(e) {
        const value = e?.target?.value?.toLowerCase().replace(/[^a-z0-9]/gi, '');
        setUsername(value)
    }

    function handleEmail(e) {
        const value = e?.target?.value?.toLowerCase();
        setEmail(value)
    }

    function handleSubmit(e) {

        e.preventDefault();

        async function fetchData() {
            const request = cancelToken();
            setIsLoading(true);
            try {
                const response = await register({ username, email, password, request });
                setIsLoading(false);
                if (!response?.data?.success) throw new Error('Error, try again')
                history.push(`/login`)
                alertDispatch({ type: "OPEN", ico: "mail", value: "Please confirm your email" });
            } catch (e) {
                if (e.isCancel !== true) {
                    alertDispatch({ type: "OPEN", ico: "error", value: defaultErrors(e) });
                    setIsLoading(false);
                }
            }
        }

        if (username.length < 5) return alertDispatch({ type: "OPEN", ico: "error", value: "Username too short" });
        if (!validateEmail(email)) return alertDispatch({ type: "OPEN", ico: "error", value: "Email not valid" });
        if (password.length < 8) return alertDispatch({ type: "OPEN", ico: "error", value: "Password not valid" });
        if (password !== confirmPassword) return alertDispatch({ type: "OPEN", ico: "error", value: "Confirm password doesn't match" });

        fetchData();

    }

    return (
        <PopupAuth
            style={{ backgroundColor: 'lightseagreen' }}
            title="Signup, it's free"
            latestUrl={latestUrl}
        >
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="input-container"><input onChange={(e) => handleUsername(e)} type="username" placeholder="@username" value={username} /></div>
                <div className="input-container"><input onChange={(e) => handleEmail(e)} type="email" placeholder="Email" value={email} /></div>
                <div className="input-container"><input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" value={password} /></div>
                <div className="input-container"><input onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirm Password" value={confirmPassword} /></div>
                <button disabled={isLoading} className="btn" type="submit">
                    <span className="loading"></span>
                    <span>Continue</span>
                </button>
            </form>
            <p>Already have an account? <Link to={'/login'}>Log In</Link>. </p>
        </PopupAuth>
    );
});