import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'
// import { Link, useNavigate } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

// components
import { SimpleOpacity } from '@components/Transitions'

// hooks
import { useAlertContext } from '@hooks/useAlertContext'
import { useStorageContext } from '@hooks/useStorageContext'

// css
import './Settings.scoped.scss';

export const Settings = ({ setShowCrypter }) => {

    // const navigate = useNavigate();
    const history = useHistory();

    const { alertDispatch } = useAlertContext();
    const { storage, dispatch } = useStorageContext();
    const token = storage?.token;

    const [isShown, setIsShown] = useState(false);
    const [hideDelay, setHideDelay] = useState(0);

    function handleClose() { setIsShown(false); setShowCrypter(false) }
    function handleOpen() { setIsShown(false); setShowCrypter(true) }

    function logout() {
        alertDispatch({
            type: "OPEN",
            value: 'Do you want to logout?',
            callback: () => {
                // navigate(`/`);
                history.push(`/`);
                dispatch({ type: 'LOGOUT' });
                handleClose();
            }
        })
    }

    return (
        <div className="settings">
            <div
                className="avatar"
                onMouseEnter={() => { setHideDelay(.5); setIsShown(true) }}
                onMouseLeave={() => { setIsShown(false); }}
            ><AnimatePresence>{storage?.user?.avatar && <SimpleOpacity><span>{storage.user.avatar}</span></SimpleOpacity>}</AnimatePresence></div>

            <AnimatePresence>
                {isShown &&
                    <motion.div
                        variants={{
                            initial: { opacity: 0 },
                            animate: { opacity: 1 },
                            exit: {
                                opacity: 0,
                                transition: { type: 'tween', duration: .3, delay: hideDelay }
                            }
                        }}
                        initial={'initial'}
                        animate={"animate"}
                        exit={"exit"}
                        transition={{ type: 'tween', duration: .3 }}
                    // onAnimationComplete={() => console.log('end')}
                    >
                        <div
                            className="list"
                            onMouseEnter={() => { setHideDelay(.5); setIsShown(true) }}
                            onMouseLeave={() => { setIsShown(false); }}
                            onClick={() => { setHideDelay(0); }}
                        >
                            <ul>
                                {token && <li><button onClick={handleOpen} className="ico-confessum">New Confessum</button></li>}
                                {token && <li><Link onClick={handleClose} className="ico-profile" to={`/@${storage?.user?.username}`}>Your profile</Link></li>}
                                {!token && <li><Link onClick={handleClose} className="ico-login" to={'/login'}>Login</Link></li>}
                                {!token && <li><Link onClick={handleClose} className="ico-register" to={'/register'}>Register</Link></li>}
                                {!token && <li className="sep"><Link onClick={handleClose} to={"/privacy-policy"}>Privacy Policy</Link></li>}
                                {!token && <li><Link onClick={handleClose} to={"/cookie-policy"}>Cookie Policy</Link></li>}
                                {!token && <li><Link onClick={handleClose} to={"/terms-of-use"}>Terms of Use</Link></li>}
                                {token && <li className="sep"><Link onClick={handleClose} className="ico-settings" to={"/settings"}>Settings</Link></li>}
                                <li><button className="ico-darkmode">Dark Mode</button></li>
                                {token && <li className="sep"><button onClick={logout} className="ico-logout">Logout</button></li>}
                            </ul>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );

}
