function isPostUrl({ pathname }) {
    let result = false;
    const urlArray = pathname.split('/');
    if (
        urlArray?.length === 3 &&
        urlArray?.[1] === 'p' &&
        urlArray?.[2]
    ) result = urlArray?.[2]
    return result;
}

function isUserUrl({ pathname }) {
    let result = false;
    const urlArray = pathname.split('/');
    if (
        urlArray?.length === 2 &&
        urlArray?.[1].startsWith('@')
    ) result = urlArray?.[1]
    return result;
}

export {
    isPostUrl,
    isUserUrl
}