// css
import './LoadingBar.scoped.scss';

export const LoadingBar = () => {

    return (

        <div className="loadingbar"></div>

    );

}