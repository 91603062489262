import { useState, useEffect } from 'react';

import uuidv4 from '@utils/uuidv4';

import './DebugLogin.scoped.scss';

const randomValue = uuidv4();

export const DebugLogin = ({ init }) => {

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (keyword === atob('YnVidQ==')) init(false); //bubu
    }, [keyword, init])

    return (
        <div className="splash-coming">
            <input
                autoComplete={`debug-input-${randomValue}`}
                name={`debug-input-${randomValue}`}
                value={keyword}
                onChange={(e) => { setKeyword(e.target.value) }}
                type="password"
                placeholder="keyword"
            />
        </div>
    );
}
