import { motion } from 'framer-motion'

const AlertTransition = ({ state, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: .9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: .9 }}
      transition={{ type: 'tween', duration: .3 }}
      style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 99999 }}
      className={`alert show--button-area--confirm${(state?.ico ? ' alert--' + state?.ico : '')}`}
    >
      {children}
    </motion.div >
  )
}

export default AlertTransition