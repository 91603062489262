import { motion } from 'framer-motion'

const SimpleOpacity = (props) => {
  return (
    <motion.div
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ zIndex: props?.style?.zIndex || 1000, position: props?.style?.position || 'static', top: 0, left: 0, width: '100%', height: '100%' }}
      transition={{ type: 'tween', duration: .3 }}
    >
      {props.children}
    </motion.div>
  )
}

export default SimpleOpacity;