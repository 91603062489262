import styled from 'styled-components';

export const Container = styled.div`
margin: 0 auto;
max-width: 1280px;
padding-left: 10px;
padding-right: 10px;
width: 100%;
position: relative;
z-index: 1;
`