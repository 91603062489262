import { motion } from 'framer-motion'

export const LoadingBarAnimation = ({ children }) => {

    return (

        <motion.div
            initial={{ height: 0 }}
            animate={{ height: 8 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: 'tween', duration: 0.3 }}
            style={{ top: '100px', zIndex: 900, position: 'fixed', width: '100%', overflow: 'hidden' }}
        >{children}</motion.div>

    );

}