import { createContext, useReducer, useMemo, useEffect } from 'react'
import moment from 'moment';

export const StorageContext = createContext()

const initialDefaultValue = {
  id: 'confessum',
  storage: {
    today: moment().format('YYYY-MM-DD')
  }
}

export const storageReducer = (state, action) => {
  const localStorageLabel = state.id;
  switch (action.type) {
    case 'SET_STORAGE':
      return { ...action.payload }
    case 'LOGOUT':
      const updatedDefaultValue = { ...{ storage: { cookies: state['storage'].cookies, ...initialDefaultValue.storage } } }
      const resetData = { id: state.id, ...updatedDefaultValue };
      if (localStorageLabel) localStorage.setItem(localStorageLabel, JSON.stringify(resetData));
      return resetData
    case 'ADD_STORAGE':
      const actionData = { [action.label]: action.payload };
      const storageData = { id: state.id, storage: { ...state['storage'], ...actionData } };
      if (localStorageLabel) localStorage.setItem(localStorageLabel, JSON.stringify(storageData));
      return storageData
    default:
      return state
  }
}

export const StorageContextProvider = ({ children }) => {

  const initialState = useMemo(() => {

    return {
      ...initialDefaultValue
    }
  }, [])

  const [state, dispatch] = useReducer(storageReducer, initialState);

  useEffect(() => {
    const label = initialState.id;
    const localStorageValue = label ? localStorage.getItem(label) : false;
    const localStorageObj = localStorageValue ? { ...JSON.parse(localStorageValue) } : false;
    dispatch({
      type: 'SET_STORAGE', payload: localStorageObj || initialState
    })
  }, [initialState])

  return (
    <StorageContext.Provider value={{ ...state, dispatch }}>
      {children}
    </StorageContext.Provider>
  )

}