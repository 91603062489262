import { Link } from "react-router-dom";
import { useEffect, useState } from 'react'

//utils
import {
    addEmoji,
    checkEmoji,
    removeEmoji,
    encrypt,
    decrypt,
    removeCf,
    checkCf,
    addCf
} from '@utils/crypter'
import uuidv4 from '@utils/uuidv4';

// css
import './Post.scoped.scss';

const switchers = [
    { id: 'none', label: "None" },
    { id: 'emoji', label: "Emoji" },
    { id: 'base64', label: "Base64" },
];

const randomValue = uuidv4();

export const Post = ({ data }) => {

    const [text, setText] = useState('');
    const [cryptText, setCryptText] = useState('');
    const [selectedSwitcher, setSelectedSwitcher] = useState(switchers[0]);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // console.log(isDecrypt);

    useEffect(() => {
        if (!data) return;
        setSelectedSwitcher({ id: data.type })
        setText(data.text)
    }, [data])

    useEffect(() => {
        // let isDecryptStatus = false;
        let updatedText = text;
        if (selectedSwitcher.id !== 'none') {
            if (password && text) {
                if (selectedSwitcher.id === 'emoji') {
                    if (checkEmoji({ text: updatedText })) {
                        updatedText = removeEmoji({ text: updatedText })
                        updatedText = checkCf({ text: decrypt({ text: updatedText, key: password }) })
                        // if (updatedText) isDecryptStatus = true;
                    } else {
                        updatedText = ''
                    }
                }
                else {
                    updatedText = checkCf({ text: decrypt({ text: updatedText, key: password }) })
                }
                if (!updatedText) {
                    updatedText = encrypt({ text: addCf({ text }), key: password })
                    updatedText = removeCf({ text: updatedText })
                    if (selectedSwitcher.id === 'emoji') {
                        updatedText = addEmoji({ text: updatedText })
                    }
                } else {
                    updatedText = removeCf({ text: updatedText })
                    // if (updatedText) isDecryptStatus = true;
                }
            }
        }
        setCryptText(updatedText);
    }, [text, password, selectedSwitcher])

    useEffect(() => {
        setShowPassword(false);
    }, [selectedSwitcher])

    return (

        <div className="post">
            <div className="header">
                <div className="avatar"></div>
                <div>
                    <div className="title">@mister</div>
                    <div className="date"><Link to={`/p/${data.id}`}>7 days ago</Link></div>
                </div>
            </div>
            {selectedSwitcher.id !== 'none' &&
                <div className="header">
                    <div className="psw">
                        <input
                            autoComplete={`post-${data.id}-${randomValue}`}
                            name={`post-${data.id}-${randomValue}`}
                            className={selectedSwitcher.id === 'none' ? 'disable' : null}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className={`showpassword${!showPassword ? ' showpassword--hide' : ''}`} onClick={() => setShowPassword(el => !el)}></div>
                    </div>
                </div>
            }

            <div className="section">
                <div className="textarea-box output">
                    <div className="textarea">{cryptText}</div>
                </div>
            </div>

            <div className="footer">
                #test #parolachiave #hashtag #ciao
            </div>

        </div>
    );

}