import http from "@services/httpService";
import qs from 'querystring'
import config from '@/config.js'

const { api } = config;

export function cancelToken() {
    return http.CancelToken.source();
}

// export async function test({ token, url, request }) {
//     return await http({
//         headers: { 'Authorization': token },
//         url: `${url}/mobile-api/v1/Login`,
//         cancelToken: request.token
//     });
// }

export async function register({ username, email, password, request }) {
    return await http({
        method: 'post',
        url: `${api}/register`,
        data: qs.stringify({ username, email, password }),
        cancelToken: request.token
    });
}

export async function login({ email, password, request }) {
    return await http({
        method: 'post',
        url: `${api}/login`,
        data: qs.stringify({ email, password }),
        cancelToken: request.token
    });
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    cancelToken,
    login,
    register
};
