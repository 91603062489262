import { useEffect } from 'react'
// import { Box } from '@components/Box'

import { Container } from '@components/Container'

// css
import './Popup.scoped.scss';

export const Popup = ({ children, handleClose }) => {

    useEffect(() => {
        function searchKeyPressHandler(e) { if (e.keyCode === 27) { handleClose() } }
        document.addEventListener("keyup", searchKeyPressHandler)
        return () => document.removeEventListener("keyup", searchKeyPressHandler)
    }, [handleClose]);

    return (

        <div className="popup">
            <div data-tip="Close" className="close" onClick={() => handleClose()}>x</div>
            <div className="popup-flex">
                <Container>
                    {children}
                </Container>
            </div>
        </div>

    );

}