import { createContext, useReducer } from 'react'

export const AlertContext = createContext()

export const alertReducer = (state, action) => {
  switch (action.type) {
    case 'CLOSE':
      state.alertReact.show = false
      return { ...state }
    case 'OPEN':
      return {
        ...state,
        alertReact: {
          show: true,
          ico: action.ico,
          text: action.value,
          callback: action.callback
        }
      }
    default:
      return state
  }
}

export const AlertContextProvider = ({ children }) => {
  const [state, alertDispatch] = useReducer(alertReducer, {
    alertReact: {
      show: false,
      ico: '',
      text: '',
      callback: false
    }
  })

  return (
    <AlertContext.Provider value={{ ...state, alertDispatch }}>
      {children}
    </AlertContext.Provider>
  )

}