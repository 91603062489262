// import { Link, useNavigate } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useState } from 'react'

// utils
import defaultErrors from '@utils/defaultErrors'
import validateEmail from '@utils/validateEmail'
import {
    emoji,
    convertEmojiStringToArray
} from '@utils/crypter'
import uuidv4 from '@utils/uuidv4';

// hooks
import { useAlertContext } from '@hooks/useAlertContext'
import { useStorageContext } from '@hooks/useStorageContext'

// components
import { PopupAuth } from '@components/PopupAuth'

// services
import { login, cancelToken } from '@services/authService'

// css
import './Login.scoped.scss';

const randomValue = uuidv4();

export const Login = (({ latestUrl }) => {

    // const navigate = useNavigate();
    const history = useHistory();

    const { alertDispatch } = useAlertContext();
    const { dispatch } = useStorageContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function handleSubmit(e) {

        e.preventDefault();

        async function fetchData() {
            const request = cancelToken();
            setIsLoading(true);
            try {
                const response = await login({ email: email.toLowerCase(), password, request });
                setIsLoading(false);
                if (!response?.data?.token) return alertDispatch({ type: "OPEN", ico: "error", value: 'Invalid credentials' });
                dispatch({ type: 'ADD_STORAGE', label: 'token', payload: response?.data?.token });
                if (response?.data?.user?.avatarNumber) {
                    response.data.user.avatar = convertEmojiStringToArray({ text: emoji })[response?.data?.user?.avatarNumber]
                }
                dispatch({ type: 'ADD_STORAGE', label: 'user', payload: response?.data?.user });
                // navigate(`/@${response?.data?.user?.username}`);
                history.push(`/@${response?.data?.user?.username}`)
            } catch (e) {
                if (e.isCancel !== true) {
                    alertDispatch({ type: "OPEN", ico: "error", value: defaultErrors(e) });
                    setIsLoading(false);
                }
            }
        }

        if (!validateEmail(email)) return alertDispatch({ type: "OPEN", ico: "error", value: "Email not valid" });
        if (password.length < 8) return alertDispatch({ type: "OPEN", ico: "error", value: "Password not valid" });

        fetchData();

    }

    return (
        <PopupAuth
            style={{ backgroundColor: 'orangered' }}
            title="Sign In"
            latestUrl={latestUrl}
        >
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="input-container">
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        value={email}
                        autoComplete={`login-email-${randomValue}`}
                        name={`login-email-${randomValue}`}
                    />
                </div>
                <div className="input-container">
                    <input
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        value={password}
                        autoComplete={`login-psw-${randomValue}`}
                        name={`login-psw-${randomValue}`}
                    />
                </div>
                <button disabled={isLoading} className="btn" type="submit">
                    <span className="loading"></span>
                    <span>Continue</span>
                </button>
            </form>
            <p>Don't you have an account yet? <Link to={'/register'}>Register now</Link>. </p>
        </PopupAuth>
    );
});