import { Link } from "react-router-dom";
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
// import Clipboard from 'react-clipboard.js';

// hooks
// import { useAlertContext } from '@hooks/useAlertContext'
import { useStorageContext } from '@hooks/useStorageContext'

//utils
import {
    addEmoji,
    checkEmoji,
    removeEmoji,
    encrypt,
    decrypt,
    removeCf,
    checkCf,
    addCf
} from '@utils/crypter'
import miniAlert from '@utils/miniAlert';
import uuidv4 from '@utils/uuidv4';

// css
import './Crypter.scoped.scss';

const randomValue = uuidv4();

const maxChar = 1024;

const switchers = [
    { id: 'none', label: "None" },
    { id: 'emoji', label: "Emoji" },
    { id: 'base64', label: "Base64" },
];

const SlideDown = (props) => {
    return (
        <motion.div
            {...props}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: props.height }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ type: 'tween', duration: .3 }}
        >
            {props.children}
        </motion.div>
    )
}

const SlideRight = (props) => {
    return (
        <motion.div
            {...props}
            initial={{ opacity: 0, width: '0' }}
            animate={{ opacity: 1, width: `${props.width}%` }}
            exit={{ opacity: 0, width: '0' }}
            transition={{ type: 'tween', duration: .3 }}
        >
            {props.children}
        </motion.div>
    )
}

export const Crypter = () => {

    const { storage } = useStorageContext();
    const token = storage?.token;

    const [text, setText] = useState('');
    const [cryptText, setCryptText] = useState('');
    const [selectedSwitcher, setSelectedSwitcher] = useState(switchers[0]);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isDecrypt, setIsDecrypt] = useState(false);

    useEffect(() => {
        let isDecryptStatus = false;
        let updatedText = text;
        if (password && text) {
            if (selectedSwitcher.id === 'emoji') {
                if (checkEmoji({ text: updatedText })) {
                    updatedText = removeEmoji({ text: updatedText })
                    updatedText = checkCf({ text: decrypt({ text: updatedText, key: password }) })
                    if (updatedText) isDecryptStatus = true;
                } else {
                    updatedText = ''
                }
            }
            else {
                updatedText = checkCf({ text: decrypt({ text: updatedText, key: password }) })
            }
            if (!updatedText) {
                updatedText = encrypt({ text: addCf({ text }), key: password })
                updatedText = removeCf({ text: updatedText })
                if (selectedSwitcher.id === 'emoji') {
                    updatedText = addEmoji({ text: updatedText })
                }
            } else {
                updatedText = removeCf({ text: updatedText })
                if (updatedText) isDecryptStatus = true;
            }
        }
        setIsDecrypt(isDecryptStatus);
        setCryptText(updatedText);
    }, [text, password, selectedSwitcher])

    useEffect(() => {
        setShowPassword(false);
    }, [selectedSwitcher])

    function handleText(e) {
        // if (text.length >= maxChar) e = e.substr(0, maxChar);
        setText(e);
    }

    function handleCopy() {
        if (cryptText) {
            navigator.clipboard.writeText(cryptText)
            miniAlert({
                overflow: false,
                autoremove: true,
                size: 'medium',
                text: 'Copied!'
            });
        }
    }

    function handleSubmit(e) {

        e.preventDefault();

        console.log(123);

        return;

    }

    return (

        <div className="box-crypter">
            <form autoComplete="off" onSubmit={(e) => { handleSubmit(e) }}>
                <div className="header">
                    <div className="switcher">
                        <ul>
                            {switchers.map(el => <li key={el.id} className={`ico--${el.id}` + (selectedSwitcher.id === el.id ? ' active' : '')} onClick={() => { setSelectedSwitcher(el) }}>{el.label}</li>)}
                        </ul>
                    </div>
                    <AnimatePresence>
                        {selectedSwitcher.id !== 'none' &&
                            <SlideRight className="psw" width={50}>
                                <input
                                    className={selectedSwitcher.id === 'none' ? 'disable' : null}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password Required"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name={`crypter-${randomValue}`}
                                    autoComplete={`crypter-${randomValue}`}
                                />
                                <div className={`showpassword${!showPassword ? ' showpassword--hide' : ''}`} onClick={() => setShowPassword(el => !el)}></div>
                                <div className="info"></div>
                            </SlideRight>
                        }
                    </AnimatePresence>
                </div>
                <div className="section">
                    <div className="textarea-box input">
                        <textarea
                            // contentEditable={true}
                            value={text}
                            onChange={(e) => handleText(e.target.value)}
                            className="textarea"
                        ></textarea>
                        {/* <div
                        contentEditable={true}
                        value={text}
                        onChange={(e) => handleText(e.target.value)}
                        className="textarea"
                    ></div> */}
                        {/* <div
                        className="textarea"
                        contentEditable='true'
                        onInput={(e) => handleText(text, e.currentTarget.textContent)}
                    >{text}</div> */}
                        <div className={`counter${text.length > maxChar ? ' counter--error' : ''}`}><span>{text.length}</span> / {maxChar}</div>
                    </div>
                </div>
                <AnimatePresence>
                    {selectedSwitcher.id !== 'none' &&
                        <div className="section">
                            <SlideDown className="textarea-box output" height={260}>
                                <div className={`decrypt-encrypt${isDecrypt ? ' decrypt' : ' encrypt'}`}>
                                    <span className="ico ico--enc"></span>
                                    {!isDecrypt && <><b>Encrypt</b> / Decrypt</>}
                                    {isDecrypt && <>Encrypt / <b>Decrypt</b></>}
                                    <span className="ico ico--dec"></span>
                                </div>
                                <div onClick={() => handleCopy()}>
                                    <textarea value={cryptText} readOnly />
                                    <div className="copy"></div>
                                </div>
                            </SlideDown>
                        </div>
                    }
                </AnimatePresence>
                <div className="footer">
                    {!token && <Link className="btn" to="/login">Login &amp; Publish</Link>}
                    {token && <Link className="btn" to="/login">Publish</Link>}
                </div>
            </form>
        </div>

    );

}